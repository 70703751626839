import React, { Fragment } from "react";
import { Link } from "gatsby";

import { talkMapping } from "../../../components/hasuracon2021/homepage/Shapes";
import playArrow from "../images/play-arrow.svg";
const presentationTypeEnum = talkMapping;

// const shapes = [Star(), Square(), Circle(), Diamond()];

const IndivSpeaker = ({ presentation, base, isScheduleSection }) => {
  const { node } = presentation;
  const { childMdx } = node;
  const { frontmatter } = childMdx;
  const { title, presenters, type, presentation_slug, video_thumb_image } = frontmatter;
  // const randomShape = shapes[Math.floor(Math.random() * shapes.length)];

  const presentationType = presentationTypeEnum[type];

  // const getFirstColumn = () => {
  //   if (isScheduleSection) {
  //     return <div id="schedule-time">{time}</div>;
  //   }
  //
  //   return <div className="talk-illus-shape">{randomShape}</div>;
  // };
  return (
    <Fragment>
      {video_thumb_image ? (
        <Link className="recordingsList" to={base + "/" + presentation_slug + "/"}>
          <div className="alignSelfStart">
            <div className="videoThumb">
              <img
                src={`${video_thumb_image.childImageSharp.original.src}`}
                alt="Video Thumbnail"
              />
              <img className="playArrow" src={playArrow} alt="play arrow" />
            </div>
            <header className="recordingsTitleBox">
              <div className={`pill ${presentationType.class}`}>
                <span>{presentationType.label}</span>
              </div>
              <div>
                <h2>{title}</h2>
              </div>
            </header>
          </div>
          <div className="recordingsPresenterBoxWrapper">
            {presenters.map((presenter, index) => {
              const { frontmatter } = presenter;
              const { name, image, title, company } = frontmatter;
              return (
                <article className="recordingsPresenterBox" key={index}>
                  <div
                    className="recordingsImageCircle"
                    style={{
                      backgroundImage: `url(${image.childImageSharp.original.src})`,
                      backgroundSize: "cover",
                    }}
                  />
                  <div>
                    <p>
                      {name}, {title ? `${title}, ${company}` : ""}
                    </p>
                    <p></p>
                  </div>
                </article>
              );
            })}
          </div>
        </Link>
      ) : null}
    </Fragment>
  );
};

export default IndivSpeaker;
