import React from "react";

const Star = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <path
        d="M24 12.002a11.961 11.961 0 00-8.486 3.518A11.968 11.968 0 0012 24c0-3.313-1.343-6.312-3.514-8.48A11.97 11.97 0 000 12.002c3.312 0 6.311-1.34 8.486-3.515A11.977 11.977 0 0012 0c.003 6.632 5.372 12.002 12 12.002z"
        fill="#FFC267"
      />
    </svg>
  );
};

const Circle = () => {
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" fill="none">
      <circle cx={6.75} cy={6.75} r={6.75} fill="#F47E7E" />
    </svg>
  );
};

function Diamond(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path transform="rotate(45 10 0)" fill="#8DD6D0" d="M10 0H24V14H10z" />
    </svg>
  );
}

function Square(props) {
  return (
    <svg width={14} height={15} viewBox="0 0 14 15" fill="none" {...props}>
      <path transform="rotate(90 14 .9)" fill="#5C94C8" d="M14 0.899475H28V14.899475H14z" />
    </svg>
  );
}

const talkMapping = {
  keynote: {
    label: "Opening Keynote",
    class: "keynote",
    shape: Star(),
  },
  closing: {
    label: "Closing Keynote",
    class: "closing",
    shape: Star(),
  },
  workshop: {
    label: "Workshop",
    class: "workshop",
    shape: Circle(),
  },
  topic: {
    label: "Topic Table",
    class: "workshop",
    shape: Circle(),
  },
  lightening: {
    label: "Lightening Talk",
    class: "lighteningTalk",
    shape: Circle(),
  },
  talk: {
    label: "Talk",
    class: "talk",
    shape: Circle(),
  },
  fireside: {
    label: "Fireside Chat",
    class: "fireside",
    shape: Circle(),
  },
  feature: {
    label: "Product Announcement",
    class: "feature",
    shape: Star(),
  },
};

export { Star, Circle, Diamond, Square, talkMapping };
